<template>
  <div class="find-page">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'Find',
  mounted() {},
};
</script>
<style scoped lang="scss">
  .find-page {
    width: 100%;
    max-width: 750px;
    margin: auto;
    min-height: 100%;
    position: relative;
    // background: #F4F5F6;
    // background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
  };
</style>
